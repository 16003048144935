.str-chat {
  flex: 1;
  max-height: 100svh !important;
}

.str-chat__main-panel {
  padding: 0 !important;
}

.str-chat__header-livestream {
  background: #fbfffe !important;
  border-radius: 0 !important;
}

.str-chat__message--me .str-chat__message-text-inner {
  background: #d3f8f1 !important;
}

.emoji-mart-emoji {
  outline: none;
}

.messaging.str-chat .str-chat__thread {
  margin-right: 0px;
  margin-top: 0px;
  border-radius: 0px;
  border-left: 1px solid #e5e9e9;
}