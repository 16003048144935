.App {
  text-align: center;
}

#detect {
  height: 1px;
  width: 1px;
  position: absolute;
  left: -999em;
  top: -999em;
}

.react-toast-notifications__container {
  z-index: 1000000000 !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.communities-list-scrollbar .ps__thumb-y,
.menu-list-scrollbar .ps__thumb-y {
  width: 11px;
}
.TopRightGrid_ScrollWrapper {
  .ReactVirtualized__Grid__innerScrollContainer {
    & > div:last-child {
      & > div {
        border-right: none !important;
      }
    }
  }
}

/* [aria-label='NEW'] {
  fill: #304659 !important;
} */
